import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ResourceName } from 'utils';
import { FilterableProperties } from 'utils';
import { TableActions, TableColumn } from 'utils';
import { CryptlexApiService } from 'ng-ui';
import { DataCacheService } from 'ng-ui';
import { ResourceTableService } from 'ng-ui';
import { ReleaseChannelFormComponent } from './release-channel-form/release-channel-form.component';
import { SEARCH_STRINGS } from 'utils';
import { PermissionsService } from 'libs/ng-ui/src/lib/_services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class ReleaseChannelService extends ResourceTableService {
  override resourceApiPath = '/v3/release-channels';
  override resourceName: ResourceName = 'release-channel';

  override tableSearchPlaceholder = SEARCH_STRINGS.name;

  override actions: TableActions = {
    create: true,
    delete: true,
    read: true,
    search: true,
    tag: false,
    update: true,
    export: false,
    selection: true,
    segments: false,
    menuActions: [
      {
        label: 'Edit',
        callback: this.launchUpdationDialog.bind(this),
        icon: 'EDIT',
        disabled: () => {
          return !this.createAllowed;
        },
      },
      {
        label: 'Delete',
        callback: this.launchDeletionDialog.bind(this),
        icon: 'DELETE',
        disabled: () => {
          return !this.deleteAllowed;
        },
      },
    ],
  };
  override get createAllowed() {
    return (
      this.writeAllowed &&
      this.permissionsService.allowedAction('product', 'read')
    );
  }
  override columns: TableColumn[] = [
    { property: 'name', displayType: 'name' },
    { property: 'displayName', displayType: 'text' },
    {
      property: 'productId',
      displayType: 'product',
      sortProperty: 'product.name',
    },
    { property: 'private', displayType: 'flag' },
    { property: 'description', displayType: 'truncateText' },
    {
      property: 'updatedAt',
      displayType: 'date',
    },
  ];

  override filterableProperties: FilterableProperties = {
    productId: 'product',
  };
  override creationComponent = ReleaseChannelFormComponent;
  override updationComponent = ReleaseChannelFormComponent;
  override _columnIdsToDisplay: string[] = [
    'name',
    'displayName',
    'productId',
    'private',
    'description',
  ];
  override tableEmptyMessage =
    "Channels help you differentiate audiences for a release. Common channel names are 'Alpha', 'Beta', and 'RC'.";

  override selections: SelectionModel<any> = new SelectionModel<any>(true, []);

  constructor(
    apiService: CryptlexApiService,
    dialog: MatDialog,
    dataCacheService: DataCacheService,
    router: Router,
    permissionsService: PermissionsService
  ) {
    super(apiService, dialog, dataCacheService, router, permissionsService);
  }
}
