import {
  GOOGLE_CLIENT_ID,
  PADDLE_TOKEN_DEV,
  PADDLE_VENDOR_ID_DEV,
  RECAPTCHA_SITE_KEY,
  STRIPE_API_KEY_DEV,
} from '../types/constants';
import {
  Env,
  ProjectEnvironmentVariables,
  projectKeys,
} from '../types/environment.types';

export const projectEnv = new Env<ProjectEnvironmentVariables>(
  {
    projectName: 'admin-portal',
    stripeApiKey: STRIPE_API_KEY_DEV,
    googleClientId: GOOGLE_CLIENT_ID,
    releaseServerBaseUrl: '',
    recaptchaSiteKey: RECAPTCHA_SITE_KEY,
    posthogApiHost: '',
    posthogApiKey: '',
    paddleVendorId: PADDLE_VENDOR_ID_DEV,
    paddleToken: PADDLE_TOKEN_DEV,
  },
  projectKeys
);
